
import * as React from 'react';

import { Row, Col} from 'react-bootstrap';
import { IResellerCustomerInvoice } from '../../interfaces/reseller-invoice';
import VoipTable from '../../components/VoipTable';
import VipBurgerMenu, { IBurgerOption } from '../../components/VipBurgerMenu';

//IResellerCustomerInvoice
type TInvoice = {
    invoice: IResellerCustomerInvoice
}


const ResellerInvoiceSummaryItem: React.FunctionComponent<TInvoice> = ({ invoice }) => {

    /*
    TODO kingy 2020-6-30 - we need to display the invoice.siteCharges instead of the line items. See screenshot in your email.
    Also we need to replicate this bit of functionality on each site charge

    if(grainutils.validString(siteCharge.outgoingCSVFilename)){
        //create a download button for the CSV
        ./downloadCalls?wholesale=false&filename=siteCharge.outgoingCSVFilename
        //create a view button
        ./downloadCalls?html=true&filename='+siteCharge.outgoingCSVFilename
    }
    if(grainutils.validString(siteCharge.resellerOutgoingCSVFilename)){
		//create a download button for the CSV
        ./downloadCalls?wholesale=true&filename=siteCharge.resellerOutgoingCSVFilename
    }
    if(grainutils.validString(siteCharge.incomingCSVFilename)){
        //create a download button
        ./downloadCalls?wholesale=false&filename='+siteCharge.incomingCSVFilename
        //create a view button
        ./downloadCalls?html=true&filename='+siteCharge.incomingCSVFilename
	}
    if(grainutils.validString(siteCharge.resellerIncomingCSVFilename)){
        //create a download button
        ./downloadCalls?wholesale=true&filename='+siteCharge.resellerIncomingCSVFilename
    }
	*/
   // console.log(invoice, invoice.siteCharges)
    const col = [
        {
            Header: 'Qty',
            accessor: 'qty',
            sortable: true
        }, {
            Header: 'Name',
            accessor: 'name',
            sortable: true
        }, {
            Header: 'Unit Value',
            accessor: 'value',
            sortable: true,
            decimalPad: true
        },{
            Header:'Wholesale Value',
            accessor:'subCost',
            sortable:true,
            decimalPad:true
        },{
            Header: 'Sub Value',
            accessor: 'subValue',
            sortable: true,
            decimalPad: true
        }, {
            Header: 'VAT',
            accessor: 'vatValue',
            sortable: true,
            decimalPad: true
        }, {
            Header: 'Total',
            accessor: 'totalValue',
            sortable: true,
            decimalPad: true
        },];

    if (invoice !== undefined) {



        return (<Col md={12}>

            <h2>{invoice.displayName}</h2>


            {invoice.siteCharges.map((change, key) => {
                const outgoingCSVFilenameCSV = '/voip/downloadCalls?wholesale=false&filename=' + change.outgoingCSVFilename;
                const outgoingCSVFilenameWeb = '/voip/downloadCalls?html=true&filename=' + change.outgoingCSVFilename;;
                const resellerOutgoingCSVFilename = '/voip/downloadCalls?wholesale=true&filename=' + change.resellerOutgoingCSVFilename;
                const incomingCSVFilenameDownload = '/voip/downloadCalls?wholesale=false&filename=' + change.incomingCSVFilename;
                const incomingCSVFilenameView = '/voip/downloadCalls?html=true&filename=' + change.incomingCSVFilename;
                const resellerIncomingCSVFilename = '/voip/downloadCalls?wholesale=true&filename=' + change.resellerIncomingCSVFilename;


                let burgerOpts: IBurgerOption[] = [];
                if (change.outgoingCSVFilename !== undefined) {
                    burgerOpts.push({ title: 'Outgoing Calls CSV', link: outgoingCSVFilenameCSV, isExternal: true });
                    burgerOpts.push({ title: 'Outgoing Calls View', link: outgoingCSVFilenameWeb, isExternal: true });
                    burgerOpts.push({ title: '', link: '', isExternal: false, isDivider: true });
                }
                if (change.resellerOutgoingCSVFilename !== undefined) {
                    burgerOpts.push({ title: 'Reseller Outgoing CSV', link: resellerOutgoingCSVFilename, isExternal: true });
                    burgerOpts.push({ title: '', link: '', isExternal: false, isDivider: true });
                }
                if (change.incomingCSVFilename !== undefined) {
                    burgerOpts.push({ title: 'Incoming Calls CSV', link: incomingCSVFilenameDownload, isExternal: true });
                    burgerOpts.push({ title: 'Incoming Calls View', link: incomingCSVFilenameView, isExternal: true });
                    burgerOpts.push({ title: '', link: '', isExternal: false, isDivider: true });
                }
                if (change.resellerIncomingCSVFilename !== undefined) {
                    burgerOpts.push({ title: 'Reseller Incoming CSV', link: resellerIncomingCSVFilename, isExternal: true });
                    burgerOpts.push({ title: '', link: '', isExternal: false, isDivider: true });
                }
                return (
                    <div key={key}>
                        <div className="d-flex flex-rowd-flex justify-content-between">
                            <div><h3>{change.name}</h3></div>
                            <div><VipBurgerMenu options={burgerOpts} /></div>
                        </div>

                        <VoipTable
                            title="Invoice Items"
                            data={change.lines}
                            col={col}
                            multiSelect={false}
                            showResultsCount={false} />


                    </div>
                );
            })}
            <Row>
                <Col md={{ offset: 0, span: 10 }} className="paddedTop text-center">

                </Col>
                <Col md={{ offset: 0, span: 2 }} className="paddedTop">
                    <div className="d-flex flex-rowd-flex justify-content-between">
                        <div><b>Sub-Total</b></div>
                        <div> £{invoice.subValue.toFixed(2)}</div>
                    </div>
                    <div className="d-flex flex-rowd-flex justify-content-between">
                        <div><b>VAT</b></div>
                        <div> £{invoice.vatValue.toFixed(2)}</div>
                    </div>
                    <div className="d-flex flex-rowd-flex justify-content-between">
                        <div><b>Total</b></div>
                        <div> £{invoice.totalValue.toFixed(2)}</div>
                    </div>
                </Col>
            </Row>
        </Col>);
    } else {
        return (<></>);
    }

};

export default ResellerInvoiceSummaryItem;
