
import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Form} from 'react-final-form';

import { ICustomerAddRequest } from '../../interfaces/customer';

import VipTextFinalField from '../../components/misc/form/VipTextFinalField';

const CustomerNewCustomerForm = ({ data, onSubmit }: any) => {
  const initalValues: ICustomerAddRequest = {
    form: 'start',
    
    customer: {
      name: '',
      website:''
    },
    contact: {
      title: '',
      firstName: '',
      lastName: '',
      telephone: '',
      emailAddress: '',
    },
    site: {
      line1: '',
      line2: '',
      city: '',
      postCode: '',
      county: '',
    }
  }
  return <Form
    onSubmit={onSubmit}
    initialValues={initalValues}
    render={({ handleSubmit, form, submitting, pristine, values }) => (

      <form onSubmit={handleSubmit}>

        <div className="text-left test-new-customer-start">
          <h2 className="text-center">New Customer</h2>
          <fieldset>

            <div className="form-group">
              <label>Name</label>

              <VipTextFinalField fieldName="customer.name" placeholder="Name" required={true} />


            </div>

          </fieldset>

          <Row>
            <Col md='6'>

              <fieldset>

                <legend>Billing  Contact</legend>

                <div className="form-group">
                  <label>Saluation</label>

                  <VipTextFinalField fieldName="contact.title" placeholder="Saluation" required={true} />

                </div>


                <div className="form-group">
                  <label>First Name</label>
                  <VipTextFinalField fieldName="contact.firstName" placeholder="First Name" required={true} />

                </div>

                <div className="form-group">
                  <label>Last Name</label>
                  <VipTextFinalField fieldName="contact.lastName" placeholder="Last Name" required={true} />
                </div>
                <div className="form-group">
                  <label>Telephone</label>
                  <VipTextFinalField fieldName="contact.telephone" placeholder="Telephone" required={true} />
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <VipTextFinalField fieldName="contact.emailAddress" placeholder="Email Address" required={true} />

                </div>
              </fieldset>
            </Col>

            <Col md='6'>

              <fieldset>


                <legend>Billing  Address</legend>

                <div className="form-group">
                  <label>Address line 1</label>
                  <VipTextFinalField fieldName="site.line1" placeholder="Line 1" required={true} />
                </div>

                <div className="form-group">
                  <label>Address line 2</label>
                  <VipTextFinalField fieldName="site.line2" placeholder="Line 2" required={true} />
                </div>
                
                <div className="form-group">
                  <label>City</label>
                  <VipTextFinalField fieldName="site.city" placeholder="City" required={true} />
                </div>

                <div className="form-group">
                  <label>County</label>
                  <VipTextFinalField fieldName="site.county" placeholder="County" required={true} />
                </div>

                <div className="form-group">
                  <label>Postcode</label>
                  <VipTextFinalField fieldName="site.postCode" placeholder="Postcode" required={true} />
                </div>
                
              </fieldset>
            </Col>
          </Row>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={submitting || pristine}>Submit</button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={form.reset}
            disabled={submitting || pristine}>Reset</button>





        </div>
      </form>
    )} />
}




export default CustomerNewCustomerForm;