import React, { useState } from "react";

import { ButtonGroup } from "react-bootstrap";

import { Form } from "react-final-form";
import VipTextFinalField from "../misc/form/VipTextFinalField";

const SidebarGeneralContact = ({ data, onSubmit }: any) => {
  const [formEna, setFormEna] = useState<boolean>(false);

  const updateData = {
    id: data.id,
    generalsalutation: data.generalsalutation,
    generalfirstName: data.generalfirstName,
    generallastName: data.generallastName,
    generaltelephoneNumber: data.generaltelephoneNumber,
    generalemailAddress: data.generalemailAddress,
  };

  const hideSubmit = (payload: any) => {
    setFormEna(false);
    return onSubmit(payload);
  };
  if (formEna === false) {
    //Not displaying form
    return (
      <div className="sidebarDiv">
        <div className="sidebarTitle">Saluation</div>
        <div className="sidebarText">{data.generalsalutation}</div>
        <div className="sidebarTitle">First Name</div>
        <div className="sidebarText">{data.generalfirstName}</div>
        <div className="sidebarTitle">Last Name</div>
        <div className="sidebarText">{data.generallastName}</div>
        <div className="sidebarTitle">Telephone</div>
        <div className="sidebarText">{data.generaltelephoneNumber}</div>
        <div className="sidebarTitle">Email</div>
        <div className="sidebarText">
          <a href={"mailto:" + data.generalemailAddress}>
            {" "}
            {data.generalemailAddress}
          </a>
        </div>

        <button
          className="btn btn-outline-primary sidebarSubButton"
          onClick={() => setFormEna(true)}
        >
          Edit general contact
        </button>
      </div>
    );
  } else {
    //form
    return (
      <div className="sidebarDiv">
        <Form
          onSubmit={hideSubmit}
          initialValues={updateData}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="sidebarTitle">Saluation</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="generalsalutation"
                  placeholder="Saluation"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">First Name</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="generalfirstName"
                  placeholder="First Name"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Last Name</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="generallastName"
                  placeholder="Last Name"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Telephone</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="generaltelephoneNumber"
                  placeholder="Telephone"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Email</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="generalemailAddress"
                  placeholder="Email Address"
                  required={true}
                />
              </div>

              <div className="side-button">
                <ButtonGroup>
                  <button
                    className="btn btn-primary sidebarFormButton"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setFormEna(false)}
                    className="btn btn-outline-primary sidebarFormButton"
                  >
                    Cancel
                  </button>
                </ButtonGroup>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
};

export default SidebarGeneralContact;
